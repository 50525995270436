.nav {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
 
.profile-dropdown {
  position: absolute;
  margin-top: 0px;
  margin-left: -45px;
  background-color: #c5bea6;
  padding: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hidden {
  display: none;
}

.logo {
  width: 150px;
  cursor: pointer;
}

.profile {
  display: flex;
  justify-content: flex-end;
}

.logout {
  min-width: fit-content;
  min-height: fit-content;
  cursor: pointer;
}