.home-left {
    width: 60%;
    min-width: 60%;
    height: 100%;
    padding-left: 20px;
}

.lists {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-width: 100%;
}

.list-container {
    width: 50%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-container.editor {
    width: 100%;
}

.list-title {
    width: 100%;
    margin: 0 0 0 5%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

.list-title h3 {
    line-height: 5px;
}

.list {
    width: 95%;
    height: 90%;
    background-color: #c5bea6;
    overflow-y: auto;
}

.list p {
    padding: 0 20px;
}

.list li {
    color: #E1DAC4;
    background-color: #575e38;
    padding: 0px 7px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
}

.list li span {
    cursor: pointer;
    padding: 5px 3px;
}

.list li:hover {
    background-color: #6a7245;
}

.list-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.list-btns button {
    width: 100%;
}

.home-right {
    width: 30%;
    max-width: 30%;
    padding-right: 30px;
}

.home-right h3 {
    line-height: 5px;
}

.home-right p {
    line-height: 20px;
    font-weight: 400;
    font-size: 8pt;
}

.tooltip .tooltext {
    visibility: hidden;
    font-size: 8pt;
    font-weight: 400;
    background-color: #c5bea6;
    color: #575e38;
    padding: 5px;
    border-bottom: 2px solid #383c24;
    border-radius: 6px;
    position:absolute;
    margin-top: -30px;
    margin-left: -50px;
    z-index: 10;
  }

  .tooltip:hover .tooltext {
    visibility: visible;
  }

  .edit-dlt {
    padding: 0;
    margin: 0;
  }

  .edit-dlt button {
    background: none;
    border: 0;
    color:#a6a67a;
    padding: 0;
    margin: 0;
  }

  .edit-dlt button:hover {
    color: rgb(190, 192, 152);
  }

  .edit-dlt .material-symbols-outlined {
    font-size: 12pt;
    margin: 0 5px;
  }

  .edit-dlt.new-inst {
    display: flex;
    justify-content: center;
    background: none;
  }

  .edit-dlt.new-inst button {
    color:#797e62;
    width: 100%;
  }

  .edit-dlt.new-inst:hover {
    background-color: #afa993;
  }

  .edit-dlt.new-inst .material-symbols-outlined {
    font-size: 18pt;
  }