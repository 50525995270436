.song-title {
    background-color: #E1DAC4;
    width: 100%;
    border: 0;
    font-size: 12pt;
    font-weight: 800;
    color: #333523;
    max-width: fit-content;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.song-btns button {
    margin-right: 10px;
}

.song-title.title {
    margin-top: 1.5%;
}

.song-title.bpm {
    font-size: 11pt;
    width: 40px;
    margin-bottom: 0;
}

.bpm {
    font-size: 10pt;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.play-song {
    margin: 0 0 20px 0;
    width: 73px;
}

.song-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-container.new-song {
    width: 100%;
    margin-bottom: 0;
}

.lists.new-song {
    margin-bottom: 0;
    max-height: 300px;
}

.err.new-song {
    width: 100%;
    text-align: center;
    max-height: 100%;
}

.lists.editor {
    margin-bottom: -30%;
}

.list li .select {
    color: #C8AD55;
    font-style: italic;
    cursor: default;
}

.song-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-controls {
    width: 70%;
}
.right-controls {
    font-size: 8pt;
    width: fit-content;
    margin-top: 1.5%;
    margin-right: 3%;
    line-height: 5px;
}

.right-controls p {
    display: flex;
    align-items: center;
}

.right-controls .material-symbols-outlined {
    font-size: 10pt;
    margin: 0 5px;
}