* {
    font-family: 'Raleway', sans-serif;
    letter-spacing: .1em;
    cursor: default;
}

body {
    background-image: url("./assets/bg.png"), url("./assets/layer02.png");
    background-repeat: repeat, no-repeat;
    background-position: center;
    background-color: #646C41;
    color: #121400;
    font-size: 10pt;
    font-weight: 600;
    height: 90vh;
}

footer {
    width: fit-content;
    margin: 20px auto;
    color: #c5bea6;
    font-size: 8pt;
}

footer a {
    color:#C8AD55;
}

footer a:hover {
    color:#333523;
}

input {
    cursor: text;
}

.container {
    width: 80%;
    max-width: 1200px;
    height: 80vh;
    max-height: 800px;
    background-color: #E1DAC4;
    background-clip: padding-box;
    padding: 1%;
    padding-top: 0;
    margin: 0 auto;
    margin-top: 4%;
    border: 15px solid #c8ad5591;
    overflow-y: auto;
}

.main {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

button {
    background-color: #333523;
    color: #DFD7CD;
    font-size: 10pt;
    font-weight: 600;
    border: 0;
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
}

button:hover {
    color: #C8AD55;
}

input {
    background-color: #c5bea6;
    color: #646C41;
    box-sizing: border-box;
    border: 1px solid #646C41;
    font-size: 10pt;
    font-weight: 600;
    padding: 5px 10px;
    margin: 10px;
    width: 400px;
}

input::placeholder {
    color: #767e54;
    font-weight: 400;
    font-style: italic;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #333523;
    color: #DFD7CD;
    font-size: 14pt;
    border: 3px solid #DFD7CD;
    padding: 5px 10px;
    margin: 10px;
  }

li {
    list-style: none;
}

a {
    color: #899147;
    font-weight: 600;
    cursor: pointer;
}

a:hover {
    color: #51636C;
    cursor: pointer;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}