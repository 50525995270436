.sample-name {
    margin-left: 5px;
}

.seq {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.seq-row {
    margin: 0;
    padding: 8px;
    padding-bottom: 14px;
    width: fit;
    background-color: #c5bea6;
    display: flex;
    align-items: center;
}

.mute {
    margin: 0 20px 0 5px;
    width: 60px;
    padding: 0;
    background-color: #646c4100;
    color:#646C41;
}

.muted {
    color: gray;
}

.off-icon span{
    color: #646C41;
    font-size: 18pt;
    cursor: pointer;
}

.on-icon span {
    margin-top: 2px;
    margin-left: 2px;
    color: #C8AD55;
    font-size: 16pt;
    font-weight: 600;
    cursor: pointer;
}

.switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 24px;
    margin: 0 5px;
  }

.switch.active-step {
    box-sizing: border-box;
    /* border: 2px solid #33352376; */
    box-shadow: 0 0 5px #fcf7e9 ;
}
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b0a991;
    -webkit-transition: .4s;
    transition: .4s;
}

  .slider:before {
    position: absolute;
}

input:checked + .slider {
    background-color: #646C41;
    font-weight: 800;
    color:#C8AD55;
    font-size: 14pt;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #646C41;
  }

  .division {
    text-align: center;
    color: #646c41d6;
    margin-top: 22px;
    margin-left: 0px;
    font-size: 10pt;
    font-weight: 800;
  }