.landing-fields label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.landing-fields input {
    display: flex;
    justify-content: flex-end;
}

.signup-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.signup-btn button {
    width: 100px;
}